import React from "react"

export default function Section5() {

  return <div className="lg:flex w-full p-10 lg:p-0">
        <div className="lg:w-2/5 md:w-full self-center pr-20">
            <p className="text-4xl text-black text-aileron-bold">
                Grow the Community you want with Ciergio’s Integrated Features
            </p>
            <div className="divider bg-accent my-8"></div>
            <img 
                className="mx-left mt-2 hidden lg:flex"
                src={require("../../../assets/images/img-bulletin-cropped.png")}
                alt=""
                width="290"
                style={{
                    marginLeft:'-13px',
                    marginBottom:'-23px',
                }}
                />
        </div>
        <div className="flex content-start flex-wrap lg:w-3/5 md:w-full">
            <div className="w-full sm:w-1/2  px-2 pb-10">
                <div className="flex items-center text-gray-700 text-center p-2">
                    <div className="w-1/6">
                        <div className="rounded-full h-65 w-65 flex items-center justify-center bg-accent text-white">
                            <img 
                                className=""
                                src={require("../../../assets/svg/icn-bulletin.svg")}
                                alt=""
                                // width='90%'
                            />
                        </div>
                    </div>
                    <div className="w-5/6 text-left text-gray-800 text-xl pl-10 font-bold">
                        Bulletin & Content Management System
                    </div>
                </div>
            </div>
            <div className="w-full sm:w-1/2 px-2 pb-10">
                <div className="flex items-center text-gray-700 text-center p-2">
                    <div className="w-1/6">
                        <div className="rounded-full h-65 w-65 flex items-center justify-center bg-accent text-white">
                            <img 
                                className=""
                                src={require("../../../assets/svg/icn-maintenance.svg")}
                                alt=""
                                // width='90%'
                            />
                        </div>
                    </div>
                    <div className="w-5/6 text-left text-gray-800 text-xl pl-10 font-bold">
                        Maintenance & Repairs
                    </div>
                </div>
            </div>
            <div className="w-full sm:w-1/2 px-2 pb-10">
                <div className="flex items-center text-gray-700 text-center p-2">
                    <div className="w-1/6">
                        <div className="rounded-full h-65 w-65 flex items-center justify-center bg-accent text-white">
                            <img 
                                className=""
                                src={require("../../../assets/svg/icn-messaging.svg")}
                                alt=""
                                // width='90%'
                            />
                        </div>
                    </div>
                    <div className="w-5/6 text-left text-gray-800 text-xl pl-10 font-bold">
                        Messaging
                    </div>
                </div>
            </div>
            <div className="w-full sm:w-1/2 px-2 pb-10">
                <div className="flex items-center text-gray-700 text-center p-2">
                    <div className="w-1/6">
                        <div className="rounded-full h-65 w-65 flex items-center justify-center bg-accent text-white">
                            <img 
                                className=""
                                src={require("../../../assets/svg/icn-paperless.svg")}
                                alt=""
                                // width='90%'
                            />
                        </div>
                    </div>
                    <div className="w-5/6 text-left text-gray-800 text-xl pl-10 font-bold">
                        Paperless Billing
                    </div>
                </div>
            </div>
            <div className="w-full sm:w-1/2 px-2 pb-10">
                <div className="flex items-center text-gray-700 text-center p-2">
                    <div className="w-1/6">
                        <div className="rounded-full h-65 w-65 flex items-center justify-center bg-accent text-white">
                            <img 
                                className=""
                                src={require("../../../assets/svg/icn-online-payment.svg")}
                                alt=""
                                // width='90%'
                            />
                        </div>
                    </div>
                    <div className="w-5/6 text-left text-gray-800 text-xl pl-10 font-bold">
                        Online Payment
                    </div>
                </div>
            </div>
            <div className="w-full sm:w-1/2 px-2 pb-10">
                <div className="flex items-center text-gray-700 text-center p-2">
                    <div className="w-1/6">
                        <div className="rounded-full h-65 w-65 flex items-center justify-center bg-accent text-white">
                            <img 
                                className=""
                                src={require("../../../assets/svg/icn-notifications.svg")}
                                alt=""
                                // width='90%'
                            />
                        </div>
                    </div>
                    <div className="w-5/6 text-left text-gray-800 text-xl pl-10 font-bold">
                        Notifications
                    </div>
                </div>
            </div>
            <div className="w-full sm:w-1/2 px-2 pb-10">
                <div className="flex items-center text-gray-700 text-center p-2">
                    <div className="w-1/6">
                        <div className="rounded-full h-65 w-65 flex items-center justify-center bg-accent text-white">
                            <img 
                                className=""
                                src={require("../../../assets/svg/icn-guest-and-delivery.svg")}
                                alt=""
                                // width='90%'
                            />
                        </div>
                    </div>
                    <div className="w-5/6 text-left text-gray-800 text-xl pl-10 font-bold">
                        Guests and Delivery E-Logbook
                    </div>
                </div>
            </div>
            <div className="w-full sm:w-1/2 px-2 pb-10">
                <div className="flex items-center text-gray-700 text-center p-2">
                    <div className="w-1/6">
                        <div className="rounded-full h-65 w-65 flex items-center justify-center bg-accent text-white">
                            <img 
                                className=""
                                src={require("../../../assets/svg/icn-directory.svg")}
                                alt=""
                                // width='90%'
                            />
                        </div>
                    </div>
                    <div className="w-5/6 text-left text-gray-800 text-xl pl-10 font-bold">
                        Online Directory
                    </div>
                </div>
            </div>
            <div className="w-full sm:w-1/2 px-2 pb-10">
                <div className="flex items-center text-gray-700 text-center p-2">
                    <div className="w-full text-center">
                        <div className="rounded-full h-65 w-200 flex items-center mx-auto justify-center bg-gray-button text-gray-800">
                            <img 
                                className="mr-3"
                                src={require("../../../assets/svg/icn-plus.svg")}
                                alt=""
                                // width='90%'
                            />
                            {/* <div className="text-gray-800 text-2xl mr-5">
                                +
                            </div>  */}
                            <span className="text-gray-800 text-xl font-bold">
                                And More!
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
}